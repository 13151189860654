
h2{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 24px;
}

p{
    font-size: 14px;
    margin-bottom: 24px;
}

.img-post-content{
    margin-bottom: 24px;
}