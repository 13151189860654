.rec.rec-arrow {
    border-radius: 0;
    border: 0px;
    background: white;
    box-shadow: none;
    color: #FC1052;
    font-size: 18px;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 0;
    border: 0px;
    background: white;
    box-shadow: none;
    color: #FC1052;
}

.rec.rec-arrow:hover:enabled {
    border-radius: 0;
    border: 0px;
    background: white;
    box-shadow: none;
    color: #FC1052;
}

.rec.rec-arrow:disabled {
    opacity: 0.3;
}

.rec.rec-arrow:focus {
    border-radius: 0;
    border: 0px;
    background: white;
    box-shadow: none;
    color: #FC1052;
    outline: none;
}

.rec.rec-arrow-right {
    position: absolute;
    bottom: 17px;
    right: 34px;
    width: 30px;
    height: 30px;
}

.rec.rec-arrow-left {
    position: absolute;
    bottom: 17px;
    right: 70px;
    width: 30px;
    height: 30px;
    z-index: 10000;
}