p {
    font-size: 16px;
    color: #7f7f7f;
}

@media (max-width: 991px){
    p {
        font-size: 12px;
    }
}
