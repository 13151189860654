html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

p {
  margin-top: 0px !important;
}

.rec-dot {
  box-shadow: none !important;
  border: 1px solid #EF1851 !important;
}

.rec-dot_active {
  background-color: #EF1851 !important;
  box-shadow: none !important;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #EF1851;
  font-size: 12px;
  border: 1px solid #EF1851;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-button:hover {
  background-color: #EF1851 !important;
  border: 1px solid #EF1851;
  color: #ffffff !important;
}
