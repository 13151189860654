.custom-timeline {
    margin-left: 20px;
}
  
.rs-timeline-item:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 45px !important;
}

.custom-timeline .rs-timeline-item-custom-dot {
    position: absolute;
    background: #fff;
    top: 0;
    left: -2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
}